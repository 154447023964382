import csrf from "@/lib/csrf.js";
import axios from "@/lib/axios.js";

export async function login(data, sessionUrl) {
  const params = new URLSearchParams();

  params.append("user[email]", data.email);
  params.append("user[password]", data.password);
  params.append("user[remember_me]", data.rememberMe ? "1" : "0");
  params.append("authenticity_token", csrf.token);

  let response;
  let redirectUrl = "/";

  try {
    response = await axios.post(sessionUrl, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
        Accept: "text/html",
      },
    });
  } catch (error) {
    return { error: error.response, redirectUrl };
  }

  const responseUrl = response.request?.responseURL;

  if (responseUrl) {
    redirectUrl = responseUrl;
  }

  return { redirectUrl };
}
