<template>
  <div class="login-form-container">
    <h1 class="login-form-card-title">Log in to OpsLevel</h1>
    <a-form
      :form="form"
      layout="vertical"
      :style="{ marginBottom: '4px' }"
      @submit.stop.prevent="handleSubmit"
    >
      <a-form-item>
        <span slot="label">Email</span>
        <a-input
          v-decorator="[
            'email',
            { rules: [{ required: true, message: 'Enter your email' }] },
          ]"
          autoFocus
        />
      </a-form-item>
      <a-form-item>
        <span slot="label">Password</span>
        <a-input
          v-decorator="[
            'password',
            { rules: [{ required: true, message: 'Enter your password' }] },
          ]"
          type="password"
        />
      </a-form-item>
      <p v-if="loginError" class="has-error" :style="{ marginTop: '4px' }">
        <span class="ant-form-explain" v-html="loginError" />
      </p>
      <a-form-item v-if="rememberable">
        <a-checkbox
          v-decorator="[
            'rememberMe',
            { initialValue: false, valuePropName: 'checked' },
          ]"
        >
          Remember Me
        </a-checkbox>
      </a-form-item>
      <a-button type="primary" htmlType="submit" :loading="loginSubmitting">
        Log in
      </a-button>
    </a-form>
    <a v-if="recoverable" id="forgot-password-link" :href="resetPasswordUrl">
      Forgot your password?
    </a>
    <a-divider />
    <a-button @click="redirectToSSOLogin">Log in with SSO</a-button>
    <span v-if="showSignUpLink" class="sign-up-prompt">
      Don't have an account?
      <br />
      <a href="https://www.opslevel.com/request-a-demo" target="_blank">
        Schedule a time to meet with our team.
      </a>
    </span>
  </div>
</template>

<script>
import { login } from "./helpers.js";
import { isSelfHosted } from "@/shared/helpers.js";
import * as routes from "@/routes.js";

export default {
  props: {
    recoverable: {
      type: Boolean,
      required: true,
    },
    rememberable: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this, {
        name: "login",
        onValuesChange: this.handleFormChange,
      }),
      loginError: null,
      loginSubmitting: false,
    };
  },

  computed: {
    ssoUrl() {
      return routes.users_sso_sign_in_path();
    },
    sessionUrl() {
      return routes.user_session_path();
    },
    resetPasswordUrl() {
      return routes.new_user_password_path();
    },
    showSignUpLink() {
      return !isSelfHosted();
    },
  },

  methods: {
    redirectToSSOLogin() {
      window.location.assign(this.ssoUrl);
    },
    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          await this.loginWithPassword(values);
        }
      });
    },
    async loginWithPassword(data) {
      this.loginSubmitting = true;

      const { error, redirectUrl } = await login(data, this.sessionUrl);

      if (error?.status === 401) {
        this.loginError = error.data;
        this.loginSubmitting = false;
      } else {
        // unexpected errors should still redirect so any queued flash messages will appear
        window.location.href = redirectUrl;
      }
    },
    handleFormChange() {
      if (this.loginError) {
        this.loginError = null;
      }
    },
  },
};
</script>

<style scoped>
@import "login.css";
</style>
